<nav class="console_menu">
    <ul class="list-unstyled menu_items" [ngClass]="{ streched: stretchMenu }">
        <li id="homeMenu" [routerLinkActive]="['active']">
            <a href="#" [routerLink]="['/dashboard']" ngbTooltip="Home" placement="right" [disableTooltip]="!stretchMenu" container="body">
                <i class="fas fa-home item_icon" aria-hidden="true"></i> Home
            </a>
        </li>
        <li
            #designAccordion
            [ngClass]="{ active: isChildActive(designAccordion, 'subDesign') && !subDesign }"
            *roleRestriction="['design.journey.write', 'design.journey.read', 'design.intentdesign.write', 'design.intentdesign.read', 'design.pagesettings.tags.write', 'design.pagesettings.tags.read', 'design.pagesettings.resource.write', 'design.pagesettings.resource.read', 'design.pagesettings.pagestatus.write', 'design.pagesettings.pagestatus.read', 'design.entity.write', 'design.entity.read']"
        >
            <a
                href="javascript:void(0)"
                ngbTooltip="Design"
                placement="right"
                [disableTooltip]="!stretchMenu"
                container="body"
                (click)="subDesign = !subDesign"
                [ngClass]="{ sub_open: subDesign, sub_closed: !subDesign }"
            >
                <i class="fas fa-pencil-ruler item_icon" aria-hidden="true"></i> Design
            </a>
            <ul class="list-unstyled sub_items" [ngClass]="{ open: subDesign }">
                <ng-container *licenseRestriction="'M_I'; itemType: 'Module'">
                    <ng-container *journeyRestriction="'ES'">
                        <li id="intentsDesignMenu" [routerLinkActive]="['active']" *roleRestriction="['design.intentdesign.write', 'design.intentdesign.read']">
                            <a href="#" [routerLink]="['/design/conversation-flow']">
                                <i class="fas fa-project-diagram item_icon" aria-hidden="true"></i> Intents design <small class="text-muted ms-1">BETA</small>
                            </a>
                        </li>
                    </ng-container>
                </ng-container>
                <ng-container *journeyRestriction="['ES']">
                    <li id="intentsSettingsMenu" [routerLinkActive]="['active']" *roleRestriction="['design.pagesettings.tags.write', 'design.pagesettings.tags.read', 'design.pagesettings.resource.write', 'design.pagesettings.resource.read', 'design.pagesettings.pagestatus.write', 'design.pagesettings.pagestatus.read', 'design.response.read', 'design.response.write']">
                        <a href="#" [routerLink]="['/design/nlu-settings/intent']"> <i class="fas fa-cogs item_icon" aria-hidden="true"></i> Intents settings </a>
                    </li>
                </ng-container>
                <ng-container *journeyRestriction="['CX']">
                    <li id="pageSettingsMenu" [routerLinkActive]="['active']" *roleRestriction="['design.pagesettings.tags.write', 'design.pagesettings.tags.read', 'design.pagesettings.resource.write', 'design.pagesettings.resource.read', 'design.pagesettings.pagestatus.write', 'design.pagesettings.pagestatus.read', 'design.response.read', 'design.response.write']">
                        <a href="#" [routerLink]="['/design/nlu-settings/page']"> <i class="fas fa-cogs item_icon" aria-hidden="true"></i> Pages settings </a>
                    </li>
                </ng-container>
                <ng-container *journeyRestriction="['ES', 'CX']">
                    <li id="entitiesMenu" [routerLinkActive]="['active']" *roleRestriction="['design.entity.write', 'design.entity.read']">
                        <a href="#" [routerLink]="['/design/nlu-settings/entities']"> <i class="fal fa-at item_icon" aria-hidden="true"></i> Entities </a>
                    </li>
                </ng-container>
                <li id="processFlowsMenu" [routerLinkActive]="['active']" *roleRestriction="['design.journey.write', 'design.journey.read']">
                    <a href="#" [routerLink]="['/process-flow']"> <i class="fas fa-shapes item_icon" aria-hidden="true"></i> Process flows </a>
                </li>
            </ul>
        </li>
        <ng-container *journeyRestriction="['ES', 'CX']">
            <li id="analyticsMenu" [routerLinkActive]="['active']" *roleRestriction="['analytics.view', 'analytics.customreport.write']">
                <a href="#" [routerLink]="['/analytics']" ngbTooltip="Analytics" placement="right" [disableTooltip]="!stretchMenu" container="body">
                    <i class="fas fa-chart-line item_icon" aria-hidden="true"></i> Analytics
                </a>
            </li>
        </ng-container>
        <ng-container *journeyRestriction="[{type: 'ES'}, {type: 'CX', fulfillment: false}]">
            <li id="trainingMenu" [routerLinkActive]="['active']" *roleRestriction="['training.write', 'training.read']">
                <a href="#" [routerLink]="['/training']" ngbTooltip="Training" placement="right" [disableTooltip]="!stretchMenu" container="body">
                    <i class="fas fa-chalkboard-teacher item_icon" aria-hidden="true"></i> Training
                </a>
            </li>
        </ng-container>
        <ng-container *journeyRestriction="['ES', 'CX']">
            <ng-container *roleRestriction="['healthcheck.validation.view', 'healthcheck.regressiontest.write', 'healthcheck.regressiontest.tester', 'healthcheck.regressiontest.read']">
                <li id="healthCheckMenu" #healthCheckAccordion [ngClass]="{ active: isChildActive(healthCheckAccordion, 'subHealthCheck') && !subHealthCheck }">
                    <a
                        href="javascript:void(0)"
                        ngbTooltip="Health check"
                        placement="right"
                        [disableTooltip]="!stretchMenu"
                        container="body"
                        (click)="subHealthCheck = !subHealthCheck"
                        [ngClass]="{ sub_open: subHealthCheck, sub_closed: !subHealthCheck }"
                    >
                        <i class="fas fa-first-aid item_icon" aria-hidden="true"></i> Health check
                    </a>
                    <ul class="list-unstyled sub_items" [ngClass]="{ open: subHealthCheck }">
                        <li id="validationMenu" [routerLinkActive]="['active']" *roleRestriction="['healthcheck.validation.view']">
                            <a href="#" [routerLink]="['/health-check/validation']"> <i class="fas fa-tasks item_icon" aria-hidden="true"></i> Validation </a>
                        </li>
                        <ng-container *journeyRestriction="'ES'">
                            <li id="regressionTestMenu" [routerLinkActive]="['active']" *roleRestriction="['healthcheck.regressiontest.write', 'healthcheck.regressiontest.tester', 'healthcheck.regressiontest.read']">
                                <a href="#" [routerLink]="['/health-check/regression-test']"> <i class="fas fa-vial item_icon" aria-hidden="true"></i> Regression Test </a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ng-container>
        </ng-container>
        <li id="monitoringMenu" [routerLinkActive]="['active']" *roleRestriction="['monitoring.status.view', 'monitoring.logs.view']">
            <a href="#" [routerLink]="['/monitoring']" ngbTooltip="monitoring" placement="right" [disableTooltip]="!stretchMenu" container="body">
                <i class="fas fa-binoculars item_icon" aria-hidden="true"></i> Monitoring
            </a>
        </li>
        <li id="billingMenu" [routerLinkActive]="['active']" *roleRestriction="['billing.view']">
            <a href="#" [routerLink]="['/billing']" ngbTooltip="Billing" placement="right" [disableTooltip]="!stretchMenu" container="body">
                <i class="fas fa-coins item_icon" aria-hidden="true"></i> Billing
            </a>
        </li>
        <li id="adminMenu" [routerLinkActive]="['active']" *roleRestriction="['admin.user.view', 'admin.user.editor', 'admin.training.write', 'admin.training.read', 'admin.auditlog.view']">
            <a href="#" [routerLink]="['/admin']" ngbTooltip="Admin" placement="right" [disableTooltip]="!stretchMenu" container="body">
                <i class="fas fa-star item_icon" aria-hidden="true"></i> Admin
            </a>
        </li>
        <!-- TEMP UNREGISTERED ROLE FOR DISABLE SECTION: -->
        <li id="versionMenu" [routerLinkActive]="['active']" *roleRestriction="['TEMP_DISABLE']">
            <a href="#" [routerLink]="['/versioning']" ngbTooltip="Versioning" placement="right" [disableTooltip]="!stretchMenu" container="body">
                <i class="fas fa-layer-group item_icon" aria-hidden="true"></i> Versioning
            </a>
        </li>
    </ul>

    <ul class="list-unstyled low_items">
        <li id="marketplaceMenu" *roleRestriction="['marketplace.write', 'marketplace.read']">
            <a href="#" [routerLink]="['/marketplace']" [routerLinkActive]="['active']" ngbTooltip="Marketplace" placement="right" [disableTooltip]="!stretchMenu" container="body">
                <i class="fas fa-shopping-cart" aria-hidden="true"></i>
            </a>
        </li>
        <ng-container *journeyRestriction="[{fulfillment: false}]">
            <li id="channelsMenu" *roleRestriction="['channel.write', 'channel.read']">
                <a href="#" [routerLink]="['/channels']" [routerLinkActive]="['active']" ngbTooltip="Marketplace" placement="right" [disableTooltip]="!stretchMenu" container="body">
                    <i class="fas fa-chart-network" aria-hidden="true"></i>
                </a>
            </li>
        </ng-container>
    </ul>
</nav>
